__turbopack_context__.v({
  "albumDesctiption": "Portfolio-module-scss-module__Xkbj7W__albumDesctiption",
  "albumTitle": "Portfolio-module-scss-module__Xkbj7W__albumTitle",
  "anim-close": "Portfolio-module-scss-module__Xkbj7W__anim-close",
  "anim-open": "Portfolio-module-scss-module__Xkbj7W__anim-open",
  "backdrop": "Portfolio-module-scss-module__Xkbj7W__backdrop",
  "buttons": "Portfolio-module-scss-module__Xkbj7W__buttons",
  "closeButton": "Portfolio-module-scss-module__Xkbj7W__closeButton",
  "content": "Portfolio-module-scss-module__Xkbj7W__content",
  "controls": "Portfolio-module-scss-module__Xkbj7W__controls",
  "filter": "Portfolio-module-scss-module__Xkbj7W__filter",
  "fixedHeader": "Portfolio-module-scss-module__Xkbj7W__fixedHeader",
  "footer": "Portfolio-module-scss-module__Xkbj7W__footer",
  "footerContent": "Portfolio-module-scss-module__Xkbj7W__footerContent",
  "galleryWrapper": "Portfolio-module-scss-module__Xkbj7W__galleryWrapper",
  "header": "Portfolio-module-scss-module__Xkbj7W__header",
  "headerContent": "Portfolio-module-scss-module__Xkbj7W__headerContent",
  "hiddenSm": "Portfolio-module-scss-module__Xkbj7W__hiddenSm",
  "layoutButtons": "Portfolio-module-scss-module__Xkbj7W__layoutButtons",
  "legal": "Portfolio-module-scss-module__Xkbj7W__legal",
  "loadMoreButton": "Portfolio-module-scss-module__Xkbj7W__loadMoreButton",
  "loginButton": "Portfolio-module-scss-module__Xkbj7W__loginButton",
  "logo": "Portfolio-module-scss-module__Xkbj7W__logo",
  "placeholderText": "Portfolio-module-scss-module__Xkbj7W__placeholderText",
  "shown": "Portfolio-module-scss-module__Xkbj7W__shown",
  "social": "Portfolio-module-scss-module__Xkbj7W__social",
  "text": "Portfolio-module-scss-module__Xkbj7W__text",
  "videoPlayer": "Portfolio-module-scss-module__Xkbj7W__videoPlayer",
  "visibleSm": "Portfolio-module-scss-module__Xkbj7W__visibleSm",
  "wrapper": "Portfolio-module-scss-module__Xkbj7W__wrapper",
});
