'use client';
import React, { useEffect, useImperativeHandle, useRef } from 'react';

import type Player from '@vimeo/player';

import { useAsyncScript } from '@bloom/library/components/hooks/useAsyncScript';

import { IVideoState, VideoPlayerImperativeHandle } from './VideoPlayer';

import style from './VimeoVideo.module.css';

const playbackEvents = ['play', 'pause', 'ended', 'timeupdate', 'seeked', 'volumechange'];

interface Props {
  onToggleControls: (open: boolean) => void;
  onUpdate: (videoState: IVideoState) => void;
  src: string;
  volume?: number;
}

const VimeoVideo = React.forwardRef<VideoPlayerImperativeHandle, Props>((props, forwardedRef) => {
  const { onToggleControls, onUpdate, src, volume = 0.75 } = props;

  const { isReady } = useAsyncScript({
    globalName: 'Vimeo',
    src: '//player.vimeo.com/api/player.js',
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const videoInfoRef = useRef<unknown>(null);
  const playerRef = useRef<Player | null>(null);

  function play() {
    if (playerRef.current) {
      playerRef.current.play();
    }
  }

  function pause() {
    if (playerRef.current) {
      playerRef.current.pause();
    }
  }

  function setVolume(val: number) {
    if (playerRef.current && Number.isFinite(val)) {
      playerRef.current.setVolume(val);
    }
  }

  function setTime(seconds: number) {
    if (playerRef.current && Number.isFinite(seconds)) {
      playerRef.current.setCurrentTime(seconds);
    }
  }

  useImperativeHandle(forwardedRef, () => {
    return {
      pause,
      play,
      setTime,
      setVolume,
    };
  }, []);

  useEffect(() => {
    function handlePlaybackEvent() {
      const promises = [
        playerRef.current?.getPaused(),
        playerRef.current?.getCurrentTime(),
        playerRef.current?.getDuration(),
        playerRef.current?.getVolume(),
      ];

      Promise.all(promises).then((res) => {
        const [isPlaying, currentTime = 0, totalTime, volume = 0.75] = res;

        onUpdate({
          aspectRatio: videoInfoRef.current?.width / videoInfoRef.current?.height || 16 / 9,
          currentTime,
          isPlaying: !isPlaying,
          totalTime,
          volume,
        });
      });
    }

    if (isReady) {
      onToggleControls(false);

      fetch(`https://vimeo.com/api/oembed.json?url=${src}`)
        .then((res) => res.json())
        .then((json) => {
          const options = {
            /**
             * IMPORTANT NOTE:
             * if a video owner has a PRO account and changed embed settings
             * background option count not work
             * in that case hide our own custom controls to avoid overlapping with vimeo ones
             */
            background: true,
            height: '100%',
            id: json.video_id,
            loop: false,
            width: '100%',
          };

          videoInfoRef.current = json;
          playerRef.current = new window.Vimeo.Player(containerRef.current, options);

          // Read commens in options object
          playerRef.current?.getPaused().then((isPaused: boolean) => {
            // isPaused === true mean a video owner has Pro account and might limited embed settings
            if (!isPaused) {
              onToggleControls(true);
            }
            playerRef.current?.setVolume(volume);
            playbackEvents.forEach((e) => playerRef.current?.on(e, handlePlaybackEvent));
          });
        });
    }
    return () => {
      if (playerRef.current) {
        playbackEvents.forEach((e) => playerRef.current?.off(e, handlePlaybackEvent));
      }
    };
  }, [isReady]);

  function handleVideoClick() {
    if (playerRef.current) {
      playerRef.current.getPaused().then((isPaused: boolean) => (isPaused ? play() : pause()));
    }
  }

  return <div className={style.wrapper} onClick={handleVideoClick} ref={containerRef} />;
});

export { VimeoVideo };
