import React from 'react';

import { IIconProps } from './types';

const FullscreenCollapse: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 14,
}) => (
  <svg className={className} height={width} viewBox="0 0 32 32" width={width}>
    <path d="M18 14h13l-5-5 6-6-3-3-6 6-5-5z" fill={color} />
    <path d="M18 18v13l5-5 6 6 3-3-6-6 5-5z" fill={color} />
    <path d="M14 18h-13l5 5-6 6 3 3 6-6 5 5z" fill={color} />
    <path d="M14 14v-13l-5 5-6-6-3 3 6 6-5 5z" fill={color} />
  </svg>
);

export default FullscreenCollapse;
