'use client';
import React, { useEffect, useMemo, useRef } from 'react';

import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { twMerge } from 'tailwind-merge';

import { useSearchParams } from '@bloom/ui/components/hooks/useSearchParams';
import { useToggle } from '@bloom/ui/components/hooks/useToggle';
import { Modal } from '@bloom/ui/components/Modal';
import { UserPic, UserPicSizeEnum } from '@bloom/ui/components/UserPic';
import { doNothing } from '@bloom/ui/utils/empty-value';

import Button from '@bloom/library/components/Button/Button';
import { LoaderSpinner } from '@bloom/library/components/Button/LoaderSpinner';
import SecondaryButton from '@bloom/library/components/Button/SecondaryButton';
import CompanyLogo from '@bloom/library/components/CompanyLogo';
import Grid from '@bloom/library/components/Gallery/Grid';
import Slideshow from '@bloom/library/components/Gallery/Slideshow';
import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { usePublicAccountByCustomUrl } from '@bloom/library/components/hooks/usePublicAccount';
import CloseIcon from '@bloom/library/components/Icon/Close';
import { Filter } from '@bloom/library/components/Nav/Filter';
import SocialLinks from '@bloom/library/components/Nav/SocialLinks';
import VideoPlayer from '@bloom/library/components/VideoPlayer/VideoPlayer';
import { getCookie } from '@bloom/library/utils/browser';
import { throttleHandler } from '@bloom/library/utils/browser';
import {
  getBusinessNameFromAccount,
  getProviderByLink,
  getUserNameFromAccount,
} from '@bloom/library/utils/misc';
import { escapeHTML } from '@bloom/library/utils/string';

import { usePortfolio } from '@bloom/portal/components/hooks/usePortfolio';

import { CtaButtonWrapper } from './CtaButton';

import style from './Portfolio.module.scss';

const Portfolio: React.FC = () => {
  const router = useRouter();
  const pathname = usePathname();

  const [isVideoModalOpen, { setFalse: closeVideoModal, setTrue: openVideoModal }] = useToggle();

  const [searchParams, setSearchParams] = useSearchParams();

  const [isHeaderFixed, { setState: setHeaderState }] = useToggle();

  const { publicAccount } = usePublicAccountByCustomUrl();
  const isLoggedIn = !!getCookie('bloom_token');

  const { data: portfolio, status } = usePortfolio(publicAccount?.user.id);

  const sortedAlbums = useMemo(() => {
    const { albumArrangement, albums } = portfolio;
    return albums.slice().sort((a, b) => albumArrangement[a.id] - albumArrangement[b.id]);
  }, [portfolio]);

  const headerNodeRef = useRef<HTMLDivElement>(null);

  const handleScroll = throttleHandler(() => {
    const { pageYOffset } = window;
    const height = headerNodeRef.current?.getBoundingClientRect().height || 0;
    if (pageYOffset > height) {
      if (!isHeaderFixed) {
        setHeaderState(true);
      }
    } else if (isHeaderFixed) {
      setHeaderState(false);
    }
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  function handleFilterChange(value: string) {
    router.push(`${pathname}?specialty=${encodeURIComponent(value)}`);
  }

  function handleCloseClick() {
    router.push('/');
  }

  function handleLoginClick() {
    router.push('/login');
  }

  function handleImageSelect(imageId: string) {
    searchParams.set('i', imageId);
    setSearchParams(searchParams);
  }

  function handleSlideshowClose() {
    if (isVideoModalOpen) {
      return;
    }

    searchParams.delete('i');
    setSearchParams(searchParams);
  }

  const { firstName, lastName } = publicAccount?.user || {};

  const selectedSpecialty =
    searchParams.get('specialty') || sortedAlbums[0]?.title || 'Portfolio Album';
  const {
    description,
    id: albumId,
    imageArrangement = {},
    imageCount,
    images = [],
    title = '',
  } = sortedAlbums.find((a) => a?.title === selectedSpecialty) || {};
  const sortedImages = images
    .slice()
    .sort((a, b) => imageArrangement[a.id] - imageArrangement[b.id]);
  // if selected images id is not set then zoomedPhotoIndex will be "-1".
  // the index of -1 causes app crash on slide click
  const selectedImageId = searchParams.get('i') || '';
  const zoomedPhotoIndex = Math.max(
    sortedImages.findIndex((i) => i.id === selectedImageId),
    0
  );
  const selectedImage = sortedImages.find((i) => i.id === selectedImageId) || {};

  function handlePlayVideoClick(id?: string) {
    let computedSelectedImage = selectedImage;
    if (id && selectedImageId !== id) {
      searchParams.set('i', id);
      setSearchParams(searchParams);

      computedSelectedImage = images.find((i) => i.id === id);
    }

    if (computedSelectedImage?.videoSource) {
      openVideoModal();
    }
  }
  if (publicAccount) {
    return (
      <div className={style.wrapper}>
        <header className={style.header} ref={headerNodeRef}>
          <Button className={style.closeButton} onClick={handleCloseClick}>
            <CloseIcon width={12} />
          </Button>
          <div className={style.headerContent}>
            <Link href="/">
              <UserPic
                className="mr-8"
                initials={getUserNameFromAccount(publicAccount)}
                size={UserPicSizeEnum.LARGE}
                src={publicAccount.user.avatar}
              />
            </Link>
            <h1
              dangerouslySetInnerHTML={{
                __html: escapeHTML(getBusinessNameFromAccount(publicAccount)),
              }}
            />
            <div className={style.headerCTAWrapper}>
              {!isLoggedIn && (
                <SecondaryButton className={style.loginButton} onClick={handleLoginClick}>
                  Login
                </SecondaryButton>
              )}
              <CtaButtonWrapper />
            </div>
          </div>

          <div className={style.controls}>
            <Filter
              className={twMerge(style.filter, style.hiddenSm)}
              onChange={handleFilterChange}
              options={sortedAlbums.map((a) => a?.title)}
              value={selectedSpecialty}
            />
          </div>
        </header>
        <header className={twMerge(style.fixedHeader, isHeaderFixed ? style.shown : '')}>
          <Link href="/">
            <CompanyLogo className={style.logo} isShort src={publicAccount.logo} />
          </Link>
          <div className={style.buttons}>
            {!isLoggedIn && (
              <SecondaryButton className={style.loginButton} onClick={handleLoginClick}>
                Login
              </SecondaryButton>
            )}
            <CtaButtonWrapper />
          </div>
          <Button className={style.closeButton} onClick={handleCloseClick}>
            <CloseIcon />
          </Button>
        </header>
        <section className={style.content}>
          <h2
            className={twMerge(style.albumTitle, style.hiddenSm)}
            dangerouslySetInnerHTML={{ __html: escapeHTML(title) }}
          />
          <Filter
            className={twMerge(style.filter, style.visibleSm)}
            onChange={handleFilterChange}
            options={sortedAlbums.map((a) => a?.title)}
            value={selectedSpecialty}
          />
          <span
            className={style.albumDesctiption}
            dangerouslySetInnerHTML={{
              __html: escapeHTML(description === undefined ? '' : description || ''),
            }}
          />

          {status === AsyncStatusEnum.PENDING && <LoaderSpinner className={style.loadMoreButton} />}

          {status !== AsyncStatusEnum.PENDING && (
            <Grid
              albumId={albumId}
              images={sortedImages}
              onImageClick={handleImageSelect}
              onPlayEmbedVideo={handlePlayVideoClick}
              portfolio={portfolio}
            />
          )}

          {status !== AsyncStatusEnum.PENDING && imageCount === 0 && (
            <div className={style.placeholderText}>
              Photographer has not added any images to
              <br />
              their <span
                dangerouslySetInnerHTML={{ __html: escapeHTML(selectedSpecialty) }}
              />{' '}
              album.
            </div>
          )}
        </section>
        <footer className={style.footer}>
          <div className={style.footerContent}>
            <SocialLinks className={style.social} links={publicAccount.socialMediaLinks} />
            <nav className={style.legal}>
              <Link href="/legal/client-terms">Terms</Link>
              <Link href="/legal/privacy">Privacy</Link>
              {/* <span>&copy; {new Date().getFullYear()} Picr</span> */}
            </nav>
          </div>
        </footer>
        <Slideshow
          images={sortedImages}
          isExpanded={!!selectedImageId}
          onClose={handleSlideshowClose}
          onImageClick={doNothing}
          onPlayEmbedVideo={handlePlayVideoClick}
          onSlideChange={handleImageSelect}
          photographerName={publicAccount.homepageName || `${firstName} ${lastName}`}
          selectedIndex={zoomedPhotoIndex}
        >
          <div>
            <SecondaryButton isDarkBackground onClick={handleLoginClick}>
              Login
            </SecondaryButton>
            <CtaButtonWrapper />
          </div>
        </Slideshow>

        <Modal
          className="bg-transparent p-0"
          onClose={closeVideoModal}
          open={isVideoModalOpen}
          size="fullscreen"
        >
          <VideoPlayer
            autoplay
            provider={getProviderByLink(selectedImage)}
            src={selectedImage?.videoSource || ''}
          />
        </Modal>
      </div>
    );
  }

  return null;
};

export default Portfolio;
