import React from 'react';

import { IIconProps } from './types';

const Pause: React.FC<IIconProps> = ({ className = '', color = 'currentColor', width = 60 }) => {
  return (
    <svg className={className} height={width} viewBox="15 24 30 30" width={width}>
      <g>
        <rect fill={color} height="20.56" width="6.875" x="21.337" y="28.721" />
        <rect fill={color} height="20.56" width="6.875" x="31.788" y="28.721" />
      </g>
    </svg>
  );
};

export default Pause;
