'use client';
import React, { useMemo } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/navigation';

import { useSearchParams } from '@bloom/ui/components/hooks/useSearchParams';
import { useToggle } from '@bloom/ui/components/hooks/useToggle';
import { Modal } from '@bloom/ui/components/Modal';
import { doNothing } from '@bloom/ui/utils/empty-value';

import { LoaderSpinner } from '@bloom/library/components/Button/LoaderSpinner';
import SecondaryButton from '@bloom/library/components/Button/SecondaryButton';
import Grid from '@bloom/library/components/Gallery/Grid';
import Slideshow from '@bloom/library/components/Gallery/Slideshow';
import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { usePublicAccountByCustomUrl } from '@bloom/library/components/hooks/usePublicAccount';
import ArrowLeftLightIcon from '@bloom/library/components/Icon/ArrowLeftLight';
import ArrowRightLightIcon from '@bloom/library/components/Icon/ArrowRightLight';
import VideoPlayer from '@bloom/library/components/VideoPlayer/VideoPlayer';
import { getCookie } from '@bloom/library/utils/browser';
import { getProviderByLink } from '@bloom/library/utils/misc';
import { escapeHTML } from '@bloom/library/utils/string';

import { usePortfolio } from '@bloom/portal/components/hooks/usePortfolio';
import { FormButtonWrapper } from '@bloom/portal/containers/public/pages/templates/minimalist/FormButton';

import style from './Portfolio.module.scss';

const Portfolio: React.FC = () => {
  const router = useRouter();
  const [searchParams, setSearchParams] = useSearchParams();

  const { publicAccount } = usePublicAccountByCustomUrl();

  const { data: portfolio, status } = usePortfolio(publicAccount?.user.id || '');

  const [isVideoModalOpen, { setFalse: closeVideoModal, setTrue: openVideoModal }] = useToggle();

  function handleImageSelect(imageId: string) {
    searchParams.set('i', imageId);
    setSearchParams(searchParams);
  }

  function handleSlideshowClose() {
    if (isVideoModalOpen) {
      return;
    }

    searchParams.delete('i');
    setSearchParams(searchParams);
  }

  function handleLoginClick() {
    router.push('/login');
  }

  const specialty = searchParams.get('specialty');
  const img = searchParams.get('i');

  const { albumArrangement, albums } = portfolio;
  const sortedAlbums = albums
    .slice()
    .sort((a, b) => albumArrangement[a.id] - albumArrangement[b.id]);
  const selectedSpecialty = specialty || sortedAlbums[0]?.title || 'Portfolio Album';
  const selectedSpecialtyIndex = sortedAlbums.findIndex((i) => i?.title === selectedSpecialty) || 0;
  const {
    description,
    id: albumId,
    imageArrangement = {},
    imageCount,
    images = [],
  } = sortedAlbums.find((a) => a?.title === selectedSpecialty) || {};
  const sortedImages = useMemo(
    () => images.slice().sort((a, b) => imageArrangement[a.id] - imageArrangement[b.id]),
    [imageArrangement, images]
  );

  // if selected images id is not set then selectedPhotoIndex will be "-1".
  // the index of -1 causes app crash on slide click
  const selectedImageId = img || '';
  const selectedPhotoIndex = Math.max(
    sortedImages.findIndex((i) => i.id === selectedImageId),
    0
  );
  const selectedImage = images.find((i) => i.id === selectedImageId) || {};
  const albumsCount = sortedAlbums.length;
  // Allow the album navigation to loop around.
  const prevSpecialty =
    selectedSpecialtyIndex > 1
      ? sortedAlbums[selectedSpecialtyIndex - 1]
      : sortedAlbums[albumsCount - 1];

  const nextSpecialty =
    selectedSpecialtyIndex < albumsCount - 1
      ? sortedAlbums[selectedSpecialtyIndex + 1]
      : sortedAlbums[0];
  const isLoggedIn = !!getCookie('bloom_token');

  function handlePlayVideoClick(id?: string) {
    let computedSelectedImage = selectedImage;
    if (id && selectedImageId !== id) {
      searchParams.set('i', id);
      setSearchParams(searchParams);

      computedSelectedImage = images.find((i) => i.id === id);
    }

    if (computedSelectedImage?.videoSource) {
      openVideoModal();
    }
  }

  return (
    <section>
      <div className={style.portfolioDescription}>
        {!!nextSpecialty?.title && (
          <Link href={`/portfolio?specialty=${encodeURIComponent(nextSpecialty.title)}`}>
            <ArrowRightLightIcon className={style.arrowRight} color="#1A1F25" width={40} />
          </Link>
        )}
        {!!prevSpecialty?.title && (
          <Link href={`/portfolio?specialty=${encodeURIComponent(prevSpecialty.title)}`}>
            <ArrowLeftLightIcon className={style.arrowLeft} color="#1A1F25" width={40} />
          </Link>
        )}
        <h1
          className={style.heading}
          dangerouslySetInnerHTML={{ __html: escapeHTML(selectedSpecialty) }}
        />
        <span
          className={style.staticText}
          dangerouslySetInnerHTML={{
            __html: escapeHTML(description === undefined ? '' : description || ''),
          }}
        />

        <div className={style.ctaButtons}>
          {!isLoggedIn && <SecondaryButton onClick={handleLoginClick}>Login</SecondaryButton>}

          <FormButtonWrapper />
        </div>
      </div>

      {status === AsyncStatusEnum.SUCCESS && imageCount === 0 ? (
        <div className={style.placeholderText}>Unfortunately, this album is empty.</div>
      ) : null}

      {status === AsyncStatusEnum.PENDING ? (
        <LoaderSpinner className={style.loadMoreButton} />
      ) : null}

      {status !== AsyncStatusEnum.PENDING ? (
        <>
          <Grid
            albumId={albumId}
            images={sortedImages}
            onImageClick={handleImageSelect}
            onPlayEmbedVideo={handlePlayVideoClick}
            portfolio={portfolio}
          />

          <Slideshow
            images={sortedImages}
            isExpanded={!!selectedImageId}
            onClose={handleSlideshowClose}
            onImageClick={doNothing}
            onPlayEmbedVideo={handlePlayVideoClick}
            onSlideChange={handleImageSelect}
            selectedIndex={selectedPhotoIndex}
          />

          <Modal
            className="p-0"
            onClose={closeVideoModal}
            open={isVideoModalOpen}
            size="fullscreen"
          >
            <VideoPlayer
              autoplay
              provider={getProviderByLink(selectedImage)}
              src={selectedImage?.videoSource || ''}
            />
          </Modal>
        </>
      ) : null}
    </section>
  );
};

export default Portfolio;
