'use client';
import { useRef, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import FullscreenCollapseIcon from '@bloom/library/components/Icon/FullscreenCollapse';
import FullscreenExpandIcon from '@bloom/library/components/Icon/FullscreenExpand';
import PauseIcon from '@bloom/library/components/Icon/Pause';
import PlayIcon from '@bloom/library/components/Icon/Play';
import VolumeMuteIcon from '@bloom/library/components/Icon/VolumeMute';
import VolumeUnmuteIcon from '@bloom/library/components/Icon/VolumeUnmute';

import styles from './PlaybackControls.module.css';

const defaultUnmutedVolume = 0.75;

interface Props {
  className?: string;
  currentTime?: number;
  isFullscreen?: boolean;
  isPlaying: boolean;
  onFullscreenToggle: VoidFunction;
  onPlayToggle: VoidFunction;
  onSeek: (seconds: number) => void;
  onVolumeChange: (volume: number) => void;
  totalTime?: number;
  volume?: number;
}

const PlaybackControls: React.FC<Props> = (props) => {
  const {
    className,
    currentTime = 0,
    isFullscreen,
    isPlaying,
    onFullscreenToggle,
    onPlayToggle,
    onSeek,
    onVolumeChange,
    totalTime = 0,
    volume = defaultUnmutedVolume,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const volumeRangeRef = useRef<HTMLInputElement>(null);

  const [previousVolume, setPreviousVolume] = useState(volume);

  function handleProgressChange(ev: React.ChangeEvent<HTMLInputElement>) {
    onSeek(ev.target.valueAsNumber);
  }

  function handleVolumeChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(ev.currentTarget.value);
    onVolumeChange(value);
  }

  function handleUnmuteClick() {
    onVolumeChange(previousVolume || 0);
    if (volumeRangeRef.current) {
      volumeRangeRef.current.value = previousVolume.toString();
    }
  }

  function handleMuteClick() {
    if (volumeRangeRef.current) {
      setPreviousVolume(volumeRangeRef.current.valueAsNumber);

      volumeRangeRef.current.value = '0';
    }
    onVolumeChange(0);
  }

  return (
    <div
      className={twMerge(
        'absolute bottom-0 left-0 right-0 flex items-center justify-center gap-3 bg-black px-3 py-2',
        className
      )}
      ref={containerRef}
    >
      <button
        className="text-white-50 inline-flex hover:text-white"
        onClick={onPlayToggle}
        type="button"
      >
        {isPlaying ? <PauseIcon width={13} /> : <PlayIcon width={13} />}
      </button>

      <input
        className={twMerge(
          styles.range,
          'bg-white-50 m-2 h-px min-w-0 flex-1 appearance-none border-0 text-black'
        )}
        defaultValue={currentTime}
        max={totalTime}
        min={0}
        onChange={handleProgressChange}
        step={1}
        type="range"
      />

      <div className="flex w-36 items-center gap-2">
        {volume === 0 ? (
          <button
            className="text-white-50 inline-flex hover:text-white"
            onClick={handleUnmuteClick}
            type="button"
          >
            <VolumeMuteIcon width={13} />
          </button>
        ) : (
          <button
            className="text-white-50 inline-flex hover:text-white"
            onClick={handleMuteClick}
            type="button"
          >
            <VolumeUnmuteIcon width={13} />
          </button>
        )}

        <input
          className={twMerge(
            styles.range,
            'bg-white-50 h-px min-w-0 flex-1 appearance-none border-0 text-black'
          )}
          defaultValue={volume}
          max={1}
          min={0}
          onChange={handleVolumeChange}
          ref={volumeRangeRef}
          step={0.01}
          type="range"
        />
      </div>

      <button
        className="text-white-50 inline-flex hover:text-white"
        onClick={onFullscreenToggle}
        type="button"
      >
        {isFullscreen ? <FullscreenCollapseIcon width={13} /> : <FullscreenExpandIcon width={13} />}
      </button>
    </div>
  );
};

export default PlaybackControls;
