'use client';
/**
 * Note.
 * Chrome 64+ have a console bug:
 * Error parsing header X-XSS-Protection: 1; mode=block; report=https://www.google.com/appserve/security-bugs/log/youtube: insecure reporting URL for secure page at character position 22. The default protections will be applied.
 * Details: https://github.com/Yoast/wordpress-seo/issues/8941#issuecomment-366444821
 */

import React, { useEffect, useImperativeHandle, useRef } from 'react';

import { useAsyncScript } from '@bloom/library/components/hooks/useAsyncScript';

import { IVideoState, VideoPlayerImperativeHandle } from './VideoPlayer';

interface Props {
  autoplay: boolean;
  onUpdate: (videoState: IVideoState) => void;
  src: string;
  volume?: number;
}

const YoutubeVideo = React.forwardRef<VideoPlayerImperativeHandle, Props>((props, forwardedRef) => {
  const { autoplay, onUpdate, src, volume = 0.75 } = props;

  const { isReady } = useAsyncScript({
    globalName: 'YT',
    src: '//www.youtube.com/iframe_api',
  });

  const playerStateInterval = useRef<ReturnType<typeof setInterval>>();

  const videoRef = useRef<YT.Player>(null);

  function setVolume(val: number) {
    if (videoRef.current && Number.isFinite(val)) {
      videoRef.current.setVolume(val * 100);
    }
  }

  function handlePlaybackEvent(data: YT.OnStateChangeEvent['data']) {
    if (videoRef.current) {
      onUpdate({
        currentTime: videoRef.current.getCurrentTime(),
        isPlaying: data !== YT.PlayerState.PLAYING,
        totalTime: videoRef.current.getDuration(),
        volume: videoRef.current.getVolume() / 100,
      });
    }
  }

  function setTime(seconds: number) {
    if (videoRef.current && Number.isFinite(seconds)) {
      videoRef.current.seekTo(seconds, true);
    }
  }

  function play() {
    if (videoRef.current) {
      videoRef.current.playVideo();
    }
  }

  function pause() {
    if (videoRef.current) {
      videoRef.current.pauseVideo();
    }
  }

  useImperativeHandle(forwardedRef, () => {
    return {
      pause,
      play,
      setTime,
      setVolume,
    };
  }, []);

  useEffect(() => {
    if (isReady && typeof window.YT !== 'undefined') {
      // http://web.archive.org/web/20160926134334/http://lasnv.net/foro/839/Javascript_parsear_URL_de_YouTube
      const videoIdRegex =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      let videoId: string;
      const match = src.match(videoIdRegex);
      if (match && match[7].length === 11) {
        videoId = match[7] || '';
      }

      window.YT.ready(() => {
        videoRef.current = new window.YT.Player('youtubePlayer', {
          events: {
            onReady: (e) => {
              setVolume(volume);
              handlePlaybackEvent(e.target.getPlayerState());
            },
            onStateChange: (e) => {
              handlePlaybackEvent(e.data);
              if (e.data === window.YT.PlayerState.PLAYING) {
                playerStateInterval.current = setInterval(() => {
                  handlePlaybackEvent(e.data);
                }, 1000);
              } else if (playerStateInterval.current) {
                clearInterval(playerStateInterval.current);
              }
            },
          },
          height: '100%',
          playerVars: {
            autohide: 1, // - hides the controls until you hover over them
            autoplay: Number(autoplay), // - no comments
            controls: 0, // - hides the bottom bar with the play button, volume, etc
            rel: 0, // - hide related videos on the end or on pause of the video
            showinfo: 0, // - makes sure the video does not display the title on the top of the video frame
          },
          videoId,
          width: '100%',
        });
      });
    }

    return () => {
      if (playerStateInterval.current) {
        clearInterval(playerStateInterval.current);
      }
    };
    // Run only once when the YT is loaded
  }, [isReady]);

  return <div id="youtubePlayer" />;
});

export { YoutubeVideo };
