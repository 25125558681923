import React from 'react';

import { twMerge } from 'tailwind-merge';

import { ChevronIcon } from '@bloom/ui/components/Icons/Chevron';
import { Select } from '@bloom/ui/components/Select';

import Button from '@bloom/library/components/Button/Button';
import {
  DropdownTrigger,
  DropdownMenu,
  DropdownMenuItem,
  Dropdown,
} from '@bloom/library/components/Floating/Dropdown';
import { escapeHTML } from '@bloom/library/utils/string';

import style from './Filter.module.scss';

interface IProps {
  className?: string;
  onChange: (value: string) => void;
  options: Array<string>;
  value: string;
}

const Filter: React.FC<IProps> = (props) => {
  const { className, onChange, options, value } = props;

  const visibleItemsCount = 5;

  function handleChange(name: string, value: string) {
    onChange(value);
  }

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    const { dataset } = e.currentTarget;
    onChange(dataset.value);
  }

  const items = options.map((opt) => {
    if (typeof opt === 'string') {
      return { label: opt, onClick: () => onChange(opt), value: opt };
    }

    return opt;
  });

  return (
    <div className={twMerge('font-regular flex items-center gap-12', style.filter, className)}>
      {items.slice(0, visibleItemsCount).map((item, index) => (
        <button
          className={twMerge(
            'hidden whitespace-nowrap text-xs capitalize sm:inline-block',
            style.link,
            item.value === value ? style.active : ''
          )}
          dangerouslySetInnerHTML={{ __html: escapeHTML(item.label) }}
          data-value={item.value}
          key={index}
          onClick={handleClick}
        />
      ))}
      {items.length > visibleItemsCount ? (
        <Dropdown placement="bottom-end">
          <DropdownTrigger asChild>
            <Button className="hidden cursor-pointer items-center gap-2 whitespace-nowrap text-xs sm:inline-flex">
              View More <ChevronIcon />
            </Button>
          </DropdownTrigger>

          <DropdownMenu
            className={twMerge(style.dropdownMenu, 'flex flex-col')}
            data-testid="sort-dropdown-menu"
          >
            {items.slice(visibleItemsCount).map(({ label, onClick }) => (
              <DropdownMenuItem
                className="flex items-center justify-between text-xs"
                key={`${label}`}
                onItemClick={onClick}
                value={value}
              >
                {label}
              </DropdownMenuItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      ) : null}

      <Select
        className="block w-full sm:hidden"
        data-testid="booking-package-select"
        menuClassName={style.selectMenu}
        name="options.bookingPackageIds"
        onChange={handleChange}
        options={items}
        value={value}
      />
    </div>
  );
};

export { Filter };
