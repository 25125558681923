import React from 'react';

function Close({ width = 12.61, className = '', color = '#000', rotate = 0 }) {
  return (
    <svg
      width={width}
      className={className}
      height={width}
      viewBox="0 0 14 14"
      transform={`rotate(${rotate})`}
    >
      <path
        d="M5.607 7.02L1.01 2.426a.998.998 0 0 1 0-1.415 1.004 1.004 0 0 1 1.415 0L7.02 5.607l4.596-4.597a.998.998 0 0 1 1.414 0c.39.39.386 1.029 0 1.415L8.435 7.02l4.596 4.596a.998.998 0 0 1 0 1.414c-.39.39-1.028.386-1.414 0L7.021 8.435l-4.596 4.596a.998.998 0 0 1-1.415 0 1.004 1.004 0 0 1 0-1.414l4.597-4.596z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Close;
