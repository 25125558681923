import React from 'react';

import PrimaryButton from '@bloom/library/components/Button/PrimaryButton';
import { usePublicAccountByCustomUrl } from '@bloom/library/components/hooks/usePublicAccount';
import { usePublicQuestionnaire } from '@bloom/library/components/hooks/usePublicQuestionnaires';
import QuoteRequestModal from '@bloom/library/components/QuoteRequest';
import {
  QuoteRequestProvider,
  useQuoteRequest,
} from '@bloom/library/components/QuoteRequest/quote-request-context';
import { escapeHTML } from '@bloom/library/utils/string';

const CtaButton: React.FC = () => {
  const { publicAccount } = usePublicAccountByCustomUrl();

  const isWebsiteActive = publicAccount?.settings?.isWebsiteActive ?? true;
  const buttonText = (publicAccount?.cta || {}).buttonText || 'Get Started';

  const [, { showQuoteRequestModal }] = useQuoteRequest();

  const handleClick = () => {
    showQuoteRequestModal();
  };

  return (
    <>
      <QuoteRequestModal isWebsiteActive={isWebsiteActive} />

      <PrimaryButton onClick={handleClick}>
        <span dangerouslySetInnerHTML={{ __html: escapeHTML(buttonText) }} />
      </PrimaryButton>
    </>
  );
};

export const CtaButtonWrapper: React.FC = () => {
  const { publicAccount } = usePublicAccountByCustomUrl();

  const { questionnaire } = usePublicQuestionnaire(
    publicAccount?.id || '',
    publicAccount?.cta?.buttonFormId
  );

  if (questionnaire) {
    return (
      <QuoteRequestProvider defaultValues={{ questionnaire }}>
        <CtaButton />
      </QuoteRequestProvider>
    );
  }

  return null;
};
