'use client';
import React, { useCallback, useEffect, useImperativeHandle, useRef } from 'react';

import { VideoPlayerImperativeHandle } from './VideoPlayer';
import { IVideoState } from './VideoPlayer';

import style from './VideoPlayer.module.css';

const extensionTypeMap = {
  mp4: 'mp4',
  ogv: 'ogg',
  webm: 'webm',
};

function getMimeTypeFromSrcPath(source: string) {
  const type = source.split('.').pop();
  return type ? `video/${extensionTypeMap[type as keyof typeof extensionTypeMap]}` : '';
}

interface Props {
  autoplay?: boolean;
  className?: string;
  loop?: boolean;
  onClick?: () => void;
  onResize?: ({ height, width }: Record<'height' | 'width', number>) => void;
  onUpdate: (videoState: IVideoState) => void;
  src: string;
  volume?: number;
}

const LocalVideo = React.forwardRef<VideoPlayerImperativeHandle, Props>((props, forwardedRef) => {
  const { autoplay, loop, onClick, onResize, onUpdate, src, volume = 0.75 } = props;

  const videoRef = useRef<HTMLVideoElement>(null);

  function setVolume(val: number) {
    if (videoRef.current && isFinite(val)) {
      videoRef.current.volume = val;
    }
  }

  function setTime(seconds: number) {
    if (videoRef.current && isFinite(seconds)) {
      videoRef.current.currentTime = seconds;
    }
  }

  function play() {
    videoRef.current?.play();
  }

  function pause() {
    videoRef.current?.pause();
  }

  useImperativeHandle(forwardedRef, () => {
    return {
      pause,
      play,
      setTime,
      setVolume,
    };
  }, []);

  const handleWindowResize = useCallback(() => {
    if (videoRef.current && onResize) {
      const { height, width } = videoRef.current.getBoundingClientRect();
      onResize({ height, width });
    }
  }, [onResize]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowResize]);

  function getAspectRatio() {
    const { videoHeight, videoWidth } = videoRef.current || {};
    return videoWidth && videoHeight ? videoWidth / videoHeight : null;
  }

  function getVideoState(): IVideoState {
    return {
      aspectRatio: getAspectRatio() || 16 / 9,
      currentTime: videoRef.current?.currentTime || 0,
      isPlaying:
        !videoRef.current?.paused &&
        !videoRef.current?.ended &&
        (videoRef.current?.currentTime || 0) > 0,
      totalTime: videoRef.current?.duration || 0,
      volume: videoRef.current?.volume || 0,
    };
  }

  function handleMetadata() {
    setVolume(volume);
    onUpdate(getVideoState());
  }

  function handlePlaybackEvent() {
    onUpdate(getVideoState());
  }

  const sources = Array.isArray(src) ? src : [src];

  return (
    <video
      autoPlay={autoplay}
      className={style.video}
      loop={loop}
      onClick={onClick}
      onLoadedMetadata={handleMetadata}
      onPause={handlePlaybackEvent}
      onPlay={handlePlaybackEvent}
      onTimeUpdate={handlePlaybackEvent}
      onVolumeChange={handlePlaybackEvent}
      ref={videoRef}
    >
      {sources.map((source) => (
        <source key={source} src={source} type={getMimeTypeFromSrcPath(source)} />
      ))}
      Your browser does not support the video element.
    </video>
  );
});

export { LocalVideo };
