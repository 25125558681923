import React from 'react';

import { IIconProps } from './types';

const Play: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 30,
  ...restProps
}) => (
  <svg className={className} height={width} viewBox="15 24 30 30" width={width} {...restProps}>
    <polygon fill={color} points="39.984,39 21.995,49.28 21.995,28.721" />
  </svg>
);

export default Play;
