import React from 'react';

import { IIconProps } from './types';

const FullscreenExpand: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 14,
}) => (
  <svg className={className} height={width} viewBox="0 0 32 32" width={width}>
    <path d="M32 0h-13l5 5-6 6 3 3 6-6 5 5z" fill={color} />
    <path d="M32 32v-13l-5 5-6-6-3 3 6 6-5 5z" fill={color} />
    <path d="M0 32h13l-5-5 6-6-3-3-6 6-5-5z" fill={color} />
    <path d="M0 0v13l5-5 6 6 3-3-6-6 5-5z" fill={color} />
  </svg>
);

export default FullscreenExpand;
