import React from 'react';

import { IIconProps } from './types';

const VolumeMute: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 14,
}) => (
  <svg className={className} height={width} viewBox="0 0 32 32" width={width}>
    <path
      d="M30 19.348v2.652h-2.652l-3.348-3.348-3.348 3.348h-2.652v-2.652l3.348-3.348-3.348-3.348v-2.652h2.652l3.348 3.348 3.348-3.348h2.652v2.652l-3.348 3.348 3.348 3.348z"
      fill={color}
    />
    <path
      d="M13 30c-0.26 0-0.516-0.102-0.707-0.293l-7.707-7.707h-3.586c-0.552 0-1-0.448-1-1v-10c0-0.552 0.448-1 1-1h3.586l7.707-7.707c0.286-0.286 0.716-0.372 1.090-0.217s0.617 0.519 0.617 0.924v26c0 0.404-0.244 0.769-0.617 0.924-0.124 0.051-0.254 0.076-0.383 0.076z"
      fill={color}
    />
  </svg>
);

export default VolumeMute;
