__turbopack_context__.v({
  "arrowLeft": "Portfolio-module-scss-module__LRKtaa__arrowLeft",
  "arrowRight": "Portfolio-module-scss-module__LRKtaa__arrowRight",
  "ctaButtons": "Portfolio-module-scss-module__LRKtaa__ctaButtons",
  "heading": "Portfolio-module-scss-module__LRKtaa__heading",
  "loadMoreButton": "Portfolio-module-scss-module__LRKtaa__loadMoreButton",
  "placeholderText": "Portfolio-module-scss-module__LRKtaa__placeholderText",
  "portfolioDescription": "Portfolio-module-scss-module__LRKtaa__portfolioDescription",
  "staticText": "Portfolio-module-scss-module__LRKtaa__staticText",
});
