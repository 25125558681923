'use client';
import { useEffect, useRef, useState } from 'react';

import screenfull from 'screenfull';
import { twMerge } from 'tailwind-merge';

import { doNothing } from '@bloom/ui/utils/empty-value';

import { LocalVideo } from './LocalVideo';
import PlaybackControls from './PlaybackControls';
import { VimeoVideo } from './VimeoVideo';
import { YoutubeVideo } from './YoutubeVideo';

import style from './VideoPlayer.module.css';

const PROVIDERS = {
  local: LocalVideo,
  vimeo: VimeoVideo,
  youtube: YoutubeVideo,
};

const fullscreenEvent = screenfull.isEnabled ? screenfull.raw.fullscreenchange : 'fullscreenchange';

export interface IVideoState {
  aspectRatio?: number;
  currentTime: number;
  isPlaying: boolean;
  totalTime: number;
  volume: number;
}

interface IProps {
  autoplay?: boolean;
  className?: string;
  controlsClassName?: string;
  fullScreenClassName?: string;
  loop?: boolean;
  muted?: boolean;
  onFullscreenToggle?: () => void;
  onVideoUpdate?: (videoState: IVideoState) => void;
  provider: keyof typeof PROVIDERS;
  src: string;
  // Number from 0 to 1
  volume?: number;
}

export interface VideoPlayerImperativeHandle {
  getAspectRatio?: () => number;
  pause: () => void;
  play: () => void;
  setTime: (time: number) => void;
  setVolume: (volume: number) => void;
}

const VideoPlayer: React.FC<IProps> = (props) => {
  const {
    autoplay,
    className,
    controlsClassName,
    fullScreenClassName,
    loop,
    muted,
    onVideoUpdate,
    provider,
    src,
  } = props;

  const [aspectRatio, setAspectRatio] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  // Currently the only VimeoVideo needs to hide playback controls
  // in some cases
  const [isControlsShown, setIsControlsShown] = useState(true);

  const [isFullscreen, setIsFullScreen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [totalTime, setTotalTime] = useState(0);
  const [volume, setVolume] = useState(muted ? 0 : props.volume);

  const containerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<VideoPlayerImperativeHandle>(null);

  useEffect(() => {
    function handleFullscreenEvent() {
      setIsFullScreen(screenfull.isFullscreen || false);
    }

    if (typeof document !== 'undefined') {
      document.addEventListener(fullscreenEvent, handleFullscreenEvent);
    }
    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener(fullscreenEvent, handleFullscreenEvent);
      }
    };
  }, []);

  function setVideoVolume(newVolume: number) {
    if (videoRef.current && isFinite(newVolume)) {
      videoRef.current.setVolume(newVolume);
    }
  }

  function getAspectRatio() {
    return (
      aspectRatio ||
      (videoRef.current && videoRef.current.getAspectRatio && videoRef.current.getAspectRatio()) ||
      16 / 9
    );
  }

  function seekVideo(time: number) {
    if (videoRef.current && isFinite(time)) {
      videoRef.current.setTime(time);
    }
  }

  function toggleVideoPlay() {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    }
  }

  function toggleFullscreen() {
    const next = !isFullscreen;

    if (screenfull.isEnabled) {
      if (next) {
        screenfull.request(containerRef.current || undefined);
      } else {
        screenfull.exit();
      }
    }
  }

  function toggleControls(newControlState: boolean | undefined) {
    setIsControlsShown((state) => newControlState ?? !state);
  }

  function handleVideoUpdate(videoState: IVideoState) {
    const { aspectRatio, currentTime, isPlaying, totalTime, volume } = videoState;

    setAspectRatio(aspectRatio ?? 16 / 9);
    setCurrentTime(currentTime);
    setIsPlaying(isPlaying);
    setTotalTime(totalTime);
    setVolume(volume);

    if (typeof onVideoUpdate === 'function') {
      onVideoUpdate(videoState);
    }
  }

  const VideoComponentProvider = PROVIDERS[provider];
  const ratio = getAspectRatio();

  // Prevent VideoPlayer from breaking
  // when src is not set.
  if (!src) {
    return null;
  }

  return (
    <div
      className={twMerge(
        style.videoContainer,
        className,
        isFullscreen ? style.isFullscreen : '',
        isFullscreen ? fullScreenClassName : ''
      )}
      ref={containerRef}
      style={{ height: `${100 / ratio}vw`, maxWidth: `${100 * ratio}vh` }}
    >
      <div className={style.videoPlayer}>
        <VideoComponentProvider
          autoplay={autoplay ?? false}
          className={style.video}
          loop={loop ?? false}
          onClick={toggleVideoPlay}
          onResize={doNothing}
          onToggleControls={toggleControls}
          onUpdate={handleVideoUpdate}
          ref={videoRef}
          src={src}
          volume={volume}
        />

        {isControlsShown && (
          <PlaybackControls
            className={twMerge(style.playbackControls, controlsClassName)}
            currentTime={currentTime}
            isFullscreen={isFullscreen}
            isPlaying={isPlaying}
            onFullscreenToggle={toggleFullscreen}
            onPlayToggle={toggleVideoPlay}
            onSeek={seekVideo}
            onVolumeChange={setVideoVolume}
            totalTime={totalTime}
            volume={volume}
          />
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
